import React from "react"
import certificado from "../images/certificado-iso.png"
import Layout from "../components/layout.js"
import { Link } from "gatsby"

export default function Images() {
  return (
    <Layout titlePage="Certificado ISO9001" titleMargin="40">
      <center>
        <img src={certificado} className="mt-16 w-4/5" />
        <Link
          to="/"
          className="col-span-1 text-xl text-center self-center font-semibold"
          style={{ fontFamily: "Source Sans Pro", color: "rgb(0, 8, 51)" }}
        >
          Clique aqui para voltar para a Home
        </Link>
      </center>
    </Layout>
  )
}
